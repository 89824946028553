import styled from 'styled-components'

export const S_Header = styled.header`
  display: flex;
  justify-content: center;
  position: relative;
  height: 117px;
  background-color: #fff;
  width: 100%;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 28px;
    background-image: url('/images/vague-entete.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
  }

  @media screen and (max-width: 768px) {
    height: 70px;
  }
`
export const S_LinearBg = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.5;
  background: linear-gradient(90deg, #29a78c 0%, #73b542 100%);
`

export const S_Logo = styled.img`
  height: 105px;
  position: absolute;
  z-index: 1;

  @media screen and (max-width: 768px) {
    height: 50px;
  }
`
