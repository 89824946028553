import styled from 'styled-components'

export const S_Div = styled.form`
  width: 900px;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 30px;
  padding-bottom: 10px;
  padding-top: 45px;
  position: relative;

  box-shadow: 2px 3px 21px 0px rgba(0, 0, 0, 0.19);

  &:before {
    content: '';
    top: 45px;
    left: -14px;
    position: absolute;
    width: 62px;
    height: 34px;
    background: linear-gradient(90deg, #e66c86 0%, #f19a5d 100%);
    -webkit-mask-image: url(/images/vague-masque.png);
    mask-image: url(/images/vague-masque.png);
    transform: rotate(90deg);
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 28px;
    background-image: url('/images/vague-multi.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
  }

  .login-wrapper {
    padding-top: 45px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 40px;
    column-gap: 70px;

    > div {
      width: 35%;
    }
  }

  .validation-btn {
    padding-top: 35px;
    padding-bottom: 30px;
    button {
      margin: auto;
    }
  }

  .spinner {
    position: absolute;
    left: calc(50% - 40px);
    top: calc(50% - 40px);
    z-index: 15;
  }

  @media screen and (max-width: 1200px) {
  }

  @media screen and (max-width: 992px) {
    width: auto;
  }

  @media screen and (max-width: 768px) {
  }

  @media screen and (max-width: 576px) {
    .login-wrapper {
      padding-top: 45px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      row-gap: 30px;
      column-gap: 0px;

      > div {
        width: 90%;
      }
    }
  }

  @media screen and (max-width: 400px) {
  }
`

export const S_Title = styled.h1`
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  padding-left: 42px;
  width: 100%;
  font-size: 1.5rem;
`
