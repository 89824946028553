import { S_Home } from './style'
import CustomScheduler from '../../components/CustomScheduler'
import { useState } from 'react'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'

const Home = () => {
  const [switchList, setSwitchList] = useState(false)
  const handleSwitch = () => {
    setSwitchList(!switchList)
  }
  return (
    <S_Home>
      <div className="s-contain">
        {switchList ? (
          <CalendarMonthIcon className="switch" onClick={handleSwitch} />
        ) : (
          <FormatListBulletedIcon className="switch" onClick={handleSwitch} />
        )}
      </div>

      <CustomScheduler switchList={switchList} />
    </S_Home>
  )
}

export default Home
