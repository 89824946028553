import styled from 'styled-components'

export const S_Container = styled.div`
  width: 100%;
  .alert-container {
    display: flex;
    flex-wrap: wrap;
  }

  .button-container {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 25px;
    row-gap: 10px;
  }
`

export const S_Div = styled.div`
  > div {
    overflow-x: overlay;
  }
  width: 100%;
  .tableS {
    position: relative;
  }
  max-width: 1800px;
  margin: auto;
  margin-top: 50px;
  .spinner {
    position: absolute;
    left: calc(50% - 40px);
    top: calc(50% - 40px);
    z-index: 15;
  }
`
