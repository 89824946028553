import styled from 'styled-components'

export const S_Home = styled.div`
  .s-contain {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
  }
  .switch {
    margin-bottom: 5px;
    cursor: pointer;
  }
`
