import styled from 'styled-components'

export const S_Div = styled.form`
  width: 900px;
  margin-inline: auto;
  margin-top: 50px;
  padding-bottom: 10px;
  position: relative;
  height: 120px;
  box-shadow: 2px 3px 21px 0px rgba(0, 0, 0, 0.19);
  display: flex;
  align-items: center;

  &:before {
    content: '';
    left: -14px;
    position: absolute;
    width: 62px;
    height: 34px;
    background: linear-gradient(90deg, #e66c86 0%, #f19a5d 100%);
    -webkit-mask-image: url(/images/vague-masque.png);
    transform: rotate(90deg);
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 28px;
    background-image: url('/images/vague-multi.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
  }

  .login-wrapper {
    display: flex;
    justify-content: center;
    width: 60%;
    gap: 15px;
    margin-right: 15px;
  }

  .validation-btn {
    width: 20%;
  }

  .spinner {
    position: absolute;
    left: calc(50% - 40px);
    z-index: 15;
  }

  @media screen and (max-width: 1200px) {
  }

  @media screen and (max-width: 992px) {
    width: auto;
    &::before {
      top: 28px;
    }
  }

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    width: auto;
    height: auto;
    padding-bottom: 30px;
    .login-wrapper {
      width: 100%;
      padding-top: 30px;
      margin-right: 0;
    }

    .validation-btn {
      width: 100%;
      padding-top: 15px;
      button {
        margin: auto;
      }
    }
  }

  @media screen and (max-width: 576px) {
    .login-wrapper {
      flex-direction: column;
      > div {
        width: 80%;
        margin: auto;
      }
    }
  }

  @media screen and (max-width: 400px) {
  }
`

export const S_Title = styled.h1`
  padding-right: 5px;
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  margin-left: 42px;
  width: 20%;
  font-size: 1.5rem;
  @media screen and (max-width: 768px) {
    width: 100%;
    height: auto;
    padding-top: 30px;
  }
`
