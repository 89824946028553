import { S_Header, S_LinearBg, S_Logo } from './style'
import { useState, useEffect } from 'react'

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}

const Header = () => {
  const { height, width } = useWindowDimensions()
  return (
    <S_Header>
      <S_Logo
        src={
          width > 768 ? '/images/CC2R.BLANC.png' : '/images/logo-cc2R-seul.png'
        }
        alt="logo de la communauté de communes des deux rives"
      />
      <S_LinearBg />
    </S_Header>
  )
}

export default Header
